<template>
  <div id="NavBar">
    <v-app-bar
      app
      dark
      class="navBar"
    >
      <v-app-bar-nav-icon
        class="mr-4"
        @click="drawer = !drawer"
      />
      <v-row align="center">
        <div
          :class="`container-logo ${
            $route.name !== 'ticket2pass' && 'pointer'
          }`"
          @click="$route.name !== 'ticket2pass' && $router.push('/')"
        >
          <img
            alt="Logo"
            :src="'/images/icons/logo.png'"
            class="logo"
          >
          <span class="logo-text">
            {{ $route.name }}
          </span>
        </div>
        <v-spacer />
      </v-row>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      dark
      temporary
      bottom
      fixed
      class="navigatorMenu"
      color="#363636"
    >
      <v-card-title class="">
        <v-spacer />
        <v-btn
          icon
          @click.stop="drawer = !drawer"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-list
        nav
        dense
        class="pt-0"
      >
        <v-list-item
          v-for="(item, index) in solutions"
          :key="index"
          link
          :to="item.link"
          active-class="bg-active"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  data () {
    return {
      drawer: false,
      items: [
        { title: 'Home', icon: 'mdi-view-dashboard' },
        { title: 'About', icon: 'mdi-forum' }
      ],
      solutions: [
        {
          link: '/',
          title: 'Inicio'
        },
        {
          link: '/estadios',
          title: 'Estadios'
        },
        {
          link: '/ferias',
          title: 'Ferias'
        },
        {
          link: '/museos',
          title: 'Museos'
        }
      ]
    }
  }
}
</script>

<style scoped>
.navBar {
  height: 70px;
  z-index: 999999999 !important;
}

.navigatorMenu {
  z-index: 999999999 !important;
}

.bg-active {
  color: #fff !important;
  caret-color: #fff !important;
  background-color: var(--ScrollColor) !important;
  border-color: var(--ScrollColor) !important;
}

.bg-active::before {
  opacity: 0.05 !important;
}

.logo-text {
  font-family: RockFordSans;
  margin-left: 10px;
  font-size: 1.7rem;
}

.logo {
  height: 45px;
}

.container-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 700px) {
  .logo {
    height: 40px;
  }
}

@media (max-width: 560px) {
  .logo {
    height: 35px;
  }

  .logo-text {
    font-size: 20px;
  }
}

@media (max-width: 452px) {
  .logo-text {
    max-width: 60vw !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media (max-width: 360px) {
  .container-logo {
    justify-content: left;
  }

  .logo-text {
    display: block;
    max-width: 75%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
