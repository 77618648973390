<template>
  <div
    id="SupportYou"
    class="max-body-container margin"
  >
    <v-row>
      <v-col cols="12">
        <TitlesComponent title="¿Como podemos apoyarle?" />
      </v-col>
      <v-col
        md="6"
        cols="12"
        class="container-img-support"
        align-self="center"
      >
        <figure class="figureSupport">
          <img
            src="/images/home/Img-3-1024.webp"
            class="img-support"
          >
          <figcaption class="titleFigCaption">
            <h2 class="bgText">
              Escríbanos o llámenos y con gusto le asesoraremos en su proyecto
            </h2>
          </figcaption>
        </figure>
      </v-col>
      <v-col
        md="6"
        cols="12"
        align-self="center"
      >
        <v-form
          ref="formSupportUs"
          class="formSupportUs"
        >
          <v-row>
            <v-col
              cols="12"
              class="mb-0 pb-0"
            >
              <v-text-field
                v-model="formSupportUs.name"
                color="#FF8136"
                label="Nombre"
                :rules="[rules.required]"
                rounded
                outlined
                dense
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              class="mb-0 pb-0"
            >
              <v-text-field
                v-model="formSupportUs.email"
                color="#FF8136"
                label="Correo Electrónico"
                :rules="[rules.required, rules.isEmail]"
                rounded
                outlined
                dense
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              class="mb-0 pb-0"
            >
              <v-text-field
                v-model="formSupportUs.phone"
                v-mask="'### ### ####'"
                color="#FF8136"
                label="Teléfono"
                :rules="[rules.required, rules.isAPhone]"
                rounded
                outlined
                dense
                hide-details="auto"
                @keypress="isNumber($event)"
              />
            </v-col>
            <v-col
              cols="12"
              class="mb-0 pb-0"
            >
              <v-select
                v-model="formSupportUs.country"
                :items="countrys"
                color="#FF8136"
                label="¿De donde nos visita?"
                :rules="[rules.required]"
                rounded
                outlined
                dense
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              class="mb-0 pb-0"
            >
              <v-select
                v-model="formSupportUs.interest"
                :items="interests"
                color="#FF8136"
                label="¿Área de interés?"
                :rules="[rules.required]"
                rounded
                outlined
                dense
                hide-details="auto"
              />
            </v-col>
            <v-col
              cols="12"
              class="mb-0 pb-0"
            >
              <v-textarea
                v-model="formSupportUs.msg"
                color="#FF8136"
                label="Tu mensaje (opcional)"
                rounded
                outlined
                dense
                hide-details="auto"
                rows="2"
                max-rows="6"
              />
            </v-col>
            <v-col cols="12">
              <center>
                <v-btn
                  :loading="loading"
                  color="#FF8136"
                  dark
                  @click="sendEmail"
                >
                  ENVIAR
                </v-btn>
              </center>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      color="success"
      shaped
      bottom
    >
      <h3>{{ text }}</h3>

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'SupportYou',
  data () {
    return {
      countrys: [
        'Centro America',
        '---------',
        'Aguascalientes',
        'Baja California',
        'Baja California Sur',
        'Campeche',
        'Chiapas',
        'Chihuahua',
        'Coahuila',
        'Colima',
        'CDMX',
        'Durango',
        'Guanajuato',
        'Guerrero',
        'Hidalgo',
        'Jalisco',
        'México',
        'Michoacán',
        'Morelos',
        'Nayarit',
        'Nuevo León',
        'Oaxaca',
        'Puebla',
        'Queretaro',
        'Quintana Roo',
        'San Luis Potosí',
        'Sinaloa',
        'Sonora',
        'Tabasco',
        'Tamaulipas',
        'Tlaxcala',
        'Veracruz',
        'Yucatán',
        'Zacatecas'
      ],
      interests: [
        'Arenas y Estadios',
        'Atracciones',
        'Convenciones y Ferias',
        'Museos y Teatros'
      ],
      formSupportUs: {
        name: '',
        email: '',
        phone: '',
        country: '',
        interest: '',
        msg: ''
      },
      rules: {
        required: (value) => !!value || 'Este campo es requerido',
        isEmail: (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(value) ||
          'El correo debe ser válido',
        isNumber: (value) => {
          const pattern = /^[0-9]+$/
          return pattern.test(value) || 'Numero no válido.'
        },
        isAPhone: (value) => {
          const pattern = /^\d{3} \d{3} \d{4}$/
          return pattern.test(value) || 'El número de teléfono debe ser valido'
        }
      },
      snackbar: false,
      text: 'Su correo se ha enviado exitosamente. En breve un asesor se pondra en contacto con usted.',
      timeout: 2000,
      loading: false
    }
  },
  methods: {
    sendEmail: function () {
      if (!this.$refs.formSupportUs.validate()) return
      this.loading = true
      const numberTrimed = this.formSupportUs.phone.trim().replaceAll(' ', '')
      const html = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta http-equiv="X-UA-Compatible" content="IE=edge">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>¿Como podemos apoyarle?</title>
        </head>
        <body>
          <h1>Solicitud de informes Ticket2pass</h1>
          <table style='font-size: 1.5em;'>
            <tbody>
              <tr>
                <td>Mi Nombre es: </td>
                <td>${this.formSupportUs.name}</td>
              </tr>
              <tr>
                <td>Mi teléfono es: </td>
                <td>${numberTrimed}</td>
              </tr>
              <tr>
                <td>Mi Correo Electrónico es: </td>
                <td>${this.formSupportUs.email}</td>
              </tr>
              <tr>
                <td>Visito desde: </td>
                <td>${this.formSupportUs.country}</td>
              </tr>
              <tr>
                <td>Me interesa: </td>
                <td>${this.formSupportUs.interest}</td>
              </tr>
              <tr>
                <td>Mensaje: </td>
                <td>${this.formSupportUs.msg}</td>
              </tr>
            </tbody>
          </table>
          <p style='font-size: 1.5em;'>Saludos!</p>
        </body>
        </html>
      `

      const data = {
        email: 'contacto@ticket2pass.com',
        html
      }
      axios
        .post(
          this.$root.myserver + this.$root.myport + '/Promtec/sendEmail',
          data
        )
        .then(
          (result) => {
            const info = result.data
            if (info.success) {
              this.snackbar = true
            }
            this.loading = false
            this.resetForm()
          },
          (error) => {
            console.log(error)
            this.loading = false
            this.resetForm()
          }
        )
    },
    resetForm: function () {
      this.formSupportUs = {
        name: '',
        email: '',
        phone: '',
        country: '',
        interest: '',
        msg: ''
      }
      this.$refs.formSupportUs.resetValidation()
      this.$refs.formSupportUs.reset()
    },
    isNumber: (evt) => {
      evt = evt ?? window.event
      const charCode = evt.which ?? evt.keyCode

      const cantUse = charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 37 || charCode > 40) && charCode !== 46
      if (cantUse) {
        evt.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped>
.textSupportYou {
  font-family: 'Panton';
  font-size: 1rem;
  color: #444;
}

.img-support {
  width: 100%;
  display: block;
  max-height: 370px;
}

.titleFigCaption {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  bottom: calc(calc(100% - 100px) - 50%);
  padding: 15px;
  font-size: 11px;
  min-width: 100%;
  box-sizing: border-box;
  height: 50px;
  text-align: center;
}

.figureSupport {
  position: relative;
  width: 100%;
}

.container-img-support {
  height: 100%;
  display: flex;
  align-self: center;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}

@media (max-width: 1400px) {
  .titleFigCaption {
    height: auto;
    text-align: center;
    padding: 15px 60px;
  }
}

@media (max-width: 959px) {
  .img-support {
    max-height: none;
  }

  .figureSupport,
  .formSupportUs {
    max-width: 500px;
    margin: 0px auto;
  }
}

@media (max-width: 499px) {
  .titleFigCaption {
    position: relative;
    bottom: 0;
    height: auto;
    text-align: center;
    padding: 15px;
  }
}
</style>
