<template>
  <div
    id="InnovativeTechnology"
    class="max-body-container margin"
  >
    <v-row class="container-innovativeTechnology">
      <v-col
        md="6"
        cols="12"
        order-md="1"
        order="12"
        align-self="center"
      >
        <v-img
          src="/images/fairs/axess_stand_expo.webp"
          contain
        />
      </v-col>
      <v-col
        md="6"
        cols="12"
        order-md="12"
        order="1"
        align-self="center"
      >
        <TitlesComponent
          title="Tecnología innovadora para mejorar la experiencia en ferias y centros de convenciones"
        />
        <p class="text-content text-justify">
          Axess es un líder en soluciones de entradas y acceso para ferias
          comerciales y centros de convenciones que lleva activo en el sector
          desde 2002 y en los últimos años nos hemos convertido en un proveedor
          integral para la gestión de visitantes.
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'InnovativeTechnology'
}
</script>

<style scoped>
@media (max-width: 959px) {
  .container-innovativeTechnology {
    max-width: 500px;
    margin: 0px auto;
  }
}
</style>
