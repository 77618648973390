<template>
  <div id="SliderFlux">
    <vue-flux
      ref="slider"
      :options="vfOptions"
      :images="images"
      :transitions="vfTransitions"
      :captions="captions"
      class="myVueFlux"
    >
      <template v-slot:preloader>
        <flux-preloader />
      </template>

      <template v-slot:caption>
        <flux-caption
          v-slot:default="captionProps"
          class="caption"
        >
          <div class="max-body-container">
            <h2>{{ captionProps.caption.text }}</h2>
            <h3>{{ captionProps.caption.subtext }}</h3>
          </div>
        </flux-caption>
      </template>

      <template v-slot:controls>
        <flux-controls />
      </template>

      <template v-slot:pagination>
        <flux-pagination v-slot:default="itemProps">
          <div
            class="cube"
            item="itemProps"
            :style="{
              'background-color': itemProps.item.active ? 'white' : 'black',
            }"
            @click="$refs.slider.show(itemProps.item.index)"
          />
        </flux-pagination>
      </template>
    </vue-flux>
  </div>
</template>

<script>
import {
  VueFlux,
  FluxPreloader,
  FluxCaption,
  FluxControls,
  FluxPagination
} from 'vue-flux'

export default {
  name: 'SliderFlux',
  components: {
    VueFlux,
    FluxPreloader,
    FluxCaption,
    FluxControls,
    FluxPagination
  },
  props: ['images', 'captions'],
  data () {
    return {
      vfOptions: {
        autoplay: true,
        bindKeys: true,
        enableGestures: true,
        autohideTime: 0
      },
      vfTransitions: [
        'book',
        'blocks1',
        'zip',
        'blinds2d',
        'blocks2',
        'waterfall',
        'round2'
      ]
    }
  }
}
</script>

<style scoped>
.myVueFlux {
  height: 80vh;
}

.cube {
  border: 1px solid white !important;
  color: white !important;
  padding: 0 8px 2px 8px !important;
  width: 25px !important;
  height: 25px !important;
  border-radius: 5px !important;
}

.caption {
  background-color: #ff8136bf !important;
  color: white !important;
  position: absolute !important;
  top: calc(50% + 20px) !important;
  width: 100% !important;
  padding: 20px !important;
  text-align: center !important;
}

.caption h2 {
  font-family: 'Panton' !important;
  font-size: 2rem !important;
  line-height: 3rem !important;
  font-weight: normal;
}

.caption h3 {
  font-family: 'Panton' !important;
  font-size: 1.5rem !important;
  line-height: 1.5rem !important;
  font-weight: normal;
}

.caption h2,
.caption h3 {
  max-width: 990px;
  margin: auto;
}

@media only screen and (max-width: 700px) {
  .cube {
    margin-top: -10px !important;
    padding: 0 8px 2px 4px !important;
    width: 15px !important;
    height: 15px !important;
  }

  .myVueFlux {
    height: calc(100vh - 56px) !important;
  }
}
</style>
