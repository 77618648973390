<template>
  <div
    id="SmartGateTurnstiles"
    class="max-body-container margin"
  >
    <v-row class="container-smartGateTurnstiles">
      <v-col
        md="4"
        cols="12"
        order-md="1"
        order="12"
        align-self="center"
      >
        <v-img
          src="/images/stadiums/smartGate-turnstiles.webp"
          contain
        />
      </v-col>
      <v-col
        md="8"
        cols="12"
        order-md="12"
        order="1"
        align-self="center"
      >
        <TitlesComponent
          :title="null"
          class="mb-3"
        >
          <h2 class="titleGeneral">
            Torniquete Pórtatil AX500 <br>
            Sistema de acceso flexible y móvil
          </h2>
        </TitlesComponent>
        <p class="text-content text-justify mx-auto">
          Su diseño sin cables con batería wifi y ruedas, permite la
          flexibilidad de configurar diferentes tipos de eventos en un mismo
          recinto con solo horas de diferencia
        </p>
        <p class="text-content text-justify mx-auto">
          Sus ruedas permiten fácil movilidad lo cual agiliza el flujo de salida
          al finalizar el evento y permite modificar la configuración de los
          accesos en pocos minutos
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SmartGateTurnstiles',
  data () {
    return {
      listSmartGateTurnstiles: [
        {
          text: 'Capacidad de detección de códigos 1D/2D, RFID y NFC.',
          sub: []
        },
        { text: 'Lectura de codigos QR en pantallas de smartphone.', sub: [] },
        {
          text: 'Flexible para el futuro debido a la modularidad de todos sus componentes.',
          sub: []
        },
        {
          text: 'Combinaciónes de colores en display para facilitar la detección de staff y visitantes.',
          sub: []
        },
        { text: '360° lector LED de 3 colores.', sub: [] },
        { text: 'Señalización acústica.', sub: [] },
        { text: 'Cómodo torniquete de 2 brazos.', sub: [] },
        {
          text: 'OPCIONES:',
          sub: [
            { text: 'Paquete de baterías', sub: [] },
            { text: 'Wifi', sub: [] },
            { text: 'Entrada / Salidas', sub: [] },
            { text: 'Mecanismo de pánico', sub: [] },
            { text: 'Paleta móvil', sub: [] }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
.titleGeneral {
  max-width: 610px !important;
}
.text-content {
  max-width: 610px !important;
}

@media (max-width: 959px) {
  .container-smartGateTurnstiles {
    max-width: 500px;
    margin: 0px auto;
  }
}
</style>
