<template>
  <div
    id="CardsGaleryData"
    class="max-body-container margin"
  >
    <v-row class="container-CardsGaleryData">
      <v-col
        v-for="itemCard in itemCards"
        :key="'Card-gallery' + itemCard.imageIndex"
        lg="4"
        md="6"
        cols="12"
      >
        <v-img
          :src="
            '/images/museums/Gallery/Card-img' + itemCard.imageIndex + '.webp'
          "
          class="gallery-card-img mb-3"
        />
        <p class="gallery-card-text text-center">
          <span class="gallery-card-number">{{ itemCard.imageIndex }}. </span>
          {{ itemCard.text }}
        </p>
      </v-col>
    </v-row>
    <!-- <TitlesComponent /> -->
  </div>
</template>

<script>
export default {
  name: 'CardsGaleryData',
  data () {
    return {
      itemCards: [
        {
          imageIndex: '1',
          text: 'Rapidez en el acceso, el sistema permite leer los tickets en solo 0.1 segundos.'
        },
        {
          imageIndex: '2',
          text: 'Boletos inteligentes y en diferentes formatos (código QR, impreso, brazalete) y seguros (protección contra usos ilícitos).'
        },
        {
          imageIndex: '3',
          text: 'Venta de tickets desde casa en línea o en el sitio.'
        },
        // ],
        // itemCardsSecond: [
        {
          imageIndex: '4',
          text: 'Venta de mercancía en el sitio con handheld.'
        },
        {
          imageIndex: '5',
          text: 'Herramientas de reporte, control y monitoreo.'
        },
        { imageIndex: '6', text: 'Capacitación y asistencia' }
      ]
    }
  }
}
</script>

<style scoped>
.gallery-card-img {
  height: 300px;
  max-width: 350px;
  border-radius: 20px;
  margin: auto;
}

.gallery-card-text {
  max-width: 320px;
  margin: auto;
}

.gallery-card-number {
  color: rgb(173, 173, 173);
  font-size: 1.2em;
  font-weight: bold;
}

@media (max-width: 959px) {
  .container-CardsGaleryData {
    max-width: 500px;
    margin: 0px auto;
  }
}
</style>
