<template>
  <div
    id="SimpleSlider"
    class="ma-n1"
  >
    <v-img :src="img" />
  </div>
</template>

<script>
export default {
  name: 'SimpleSlider',
  props: ['img']
}
</script>

<style></style>
