<template>
  <div id="Home">
    <SliderFlux
      :images="images"
      :captions="captions"
      data-wow-duration=".5s"
      class="wow fadeInDown"
    />
    <AboutUs
      data-wow-duration=".5s"
      data-wow-delay=".5s"
      class="wow fadeInUp"
    />
    <Solutions
      data-wow-duration=".5s"
      data-wow-delay=".5s"
      class="wow fadeInRight"
    />
    <Equipment
      data-wow-duration=".5s"
      data-wow-delay=".5s"
      class="wow fadeInLeft"
    />
    <SupportYou
      data-wow-duration=".5s"
      data-wow-delay=".5s"
      class="wow fadeInUp"
    />
  </div>
</template>

<script>
import SliderFlux from '@/components/Tools/SliderFlux.vue'
import AboutUs from '@/components/home/AboutUs.vue'
import Solutions from '@/components/home/Solutions.vue'
import Equipment from '@/components/home/Equipment.vue'
import SupportYou from '@/components/home/SupportYou.vue'

export default {
  name: 'HomeView',
  components: {
    SliderFlux,
    AboutUs,
    Solutions,
    Equipment,
    SupportYou
  },
  data () {
    return {
      images: ['/images/home/slide1.webp', '/images/home/slide2.webp'],
      captions: [
        { text: 'Sistemas de acceso portátiles e inalámbricos', subtext: '' },
        {
          text: 'Equipos innovadores y flexibles',
          subtext: ''
        }
      ]
    }
  },
  mounted () {
    this.$root.toTopForce()
  }
}
</script>

<style scoped></style>
