<template>
  <div id="FooterMain">
    <v-footer
      dark
      padless
    >
      <v-card
        flat
        tile
        class="white--text text-center wow fadeInUp"
        width="100%"
        data-wow-duration=".5s"
      >
        <v-card-text class="py-3">
          <v-tooltip
            v-for="(contact, index) in contactNumber"
            :key="'Social' + index"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-4 white--text"
                :href="contact.link"
                :target="contact.newTab ? '_blank' : '_self'"
                v-bind="attrs"
                text
                small
                v-on="on"
              >
                <v-icon
                  size="24px"
                  class="mr-1"
                >
                  {{ contact.icon }}
                </v-icon>
                {{ contact.name }}
              </v-btn>
            </template>
            <span>Contactar con {{ contact.name }}</span>
          </v-tooltip>
        </v-card-text>

        <v-divider />

        <v-card-text class="py-3 white--text full-padding-phone">
          <span class="hidden-version">v{{ getVersion }}</span>
          {{ new Date().getFullYear() }} — <strong>ticket2pass</strong> <br>
          <strong>Promtec</strong> <br>
          Calle 38 A por 27 (Esquina) Núm. Ext. 153 Col. Buenavista C.P 97127 Mérida, Yucatán, México.
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import { version } from '@/../package.json'

export default {
  name: 'FooterMain',
  data () {
    return {
      icons: ['mdi-wa', 'mdi-twitter', 'mdi-linkedin', 'mdi-instagram'],
      contactNumber: [
        {
          icon: 'mdi-whatsapp',
          name: 'ventas',
          link: '//wa.me/+5219992351145',
          newTab: true
        },
        {
          icon: 'mdi-whatsapp',
          name: 'soporte',
          link: '//wa.me/+5219992677845',
          newTab: true
        },
        {
          icon: 'mdi-email',
          name: 'Mesa de servicios',
          link: '/mesa-de-servicios',
          newTab: true
        }
      ]
    }
  },
  computed: { getVersion: () => version }
}
</script>

<style scoped>
#FooterMain {
  margin-top: 30px;
}

.full-padding-phone {
  position: relative;
}

.hidden-version {
  opacity: .2;
  position: absolute;
  bottom: 1px;
  left: 5px;
}

@media (max-width: 700px) {
  .full-padding-phone {
    padding-bottom: 25px !important;
    padding-top: 20px !important;
  }
}
</style>
