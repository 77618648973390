<template>
  <div
    id="AccessVersatility"
    class="max-body-container margin"
  >
    <v-row class="container-accessVersatility">
      <v-col
        md="5"
        cols="12"
        order-md="1"
        order="12"
        align-self="center"
      >
        <v-img
          src="/images/stadiums/access-versatility.webp"
          height="540px"
          position="54% 50%"
        />
      </v-col>
      <v-col
        md="7"
        cols="12"
        order-md="12"
        order="1"
        align-self="center"
      >
        <v-row>
          <v-col cols="12">
            <TitlesComponent title="Versatilidad de accesos" />
          </v-col>
          <v-col
            v-for="(ticket, index) in tickets"
            :key="`ticket-${index}`"
            md="6"
            cols="12"
          >
            <img
              :alt="'accessVersatility' + index"
              :src="ticket.img"
              class="accessVersatility-img"
            >
            <h3 class="title-accessVersatility content-accessVersatility">
              {{ ticket.title }}
            </h3>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'AccessVersatility',
  data () {
    return {
      tickets: [
        {
          img: '/images/stadiums/digital-tickets.webp',
          title: 'Boleto Digital'
        },
        {
          img: '/images/stadiums/physical-tickets.webp',
          title: 'Boleto Físico'
        },
        {
          img: '/images/stadiums/self-printed-ticket.webp',
          title: 'Boleto auto impreso'
        },
        {
          img: '/images/stadiums/smart-card.webp',
          title: 'Tarjeta inteligente'
        }
      ]
    }
  }
}
</script>

<style scoped>
.accessVersatility-img {
  height: 165px;
  display: block;
  max-width: 100%;
  margin: 7px auto;
  margin-bottom: 15px;
}

.title-accessVersatility {
  color: rgb(75, 75, 75);
  font-family: 'Panton';
  font-size: 19px;
  text-align: center;
}

@media (max-width: 959px) {
  .accessVersatility {
    max-width: 300px;
    margin: 0px auto;
  }
  .accessVersatility-img {
    height: 150px;
  }
  .container-accessVersatility {
    max-width: 500px;
    margin: 0px auto;
  }
}
</style>
