<template>
  <div
    id="YouChooseYourTicket"
    class="max-body-container margin"
  >
    <v-row class="container-youHaveTheControl">
      <v-col cols="12">
        <v-img
          src="/images/museums/fotoPaginaWeb.webp"
          max-height="360px"
          position="50% 19%"
        />
      </v-col>
      <v-col cols="12">
        <TitlesComponent>
          <h2 class="titleGeneral">
            ¡Tu eliges el tipo de boleto para acceder a tu atracción favorita!
          </h2>
          <h3 class="titleGeneral">
            Una solución innovadora de boletaje inteligente
          </h3>
        </TitlesComponent>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'YouChooseYourTicket'
}
</script>

<style scoped>
@media (max-width: 959px) {
  .container-youHaveTheControl {
    max-width: 500px;
    margin: 0px auto;
  }
}
</style>
