import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Titles from '@/components/Tools/Titles.vue'
import TitlePage from '@/components/Tools/TitlePage.vue'
import SimpleSlider from '@/components/Tools/SimpleSlider.vue'
import WOW from 'wowjs'
import 'wowjs/css/libs/animate.css'
import { VueMaskDirective } from 'v-mask'

Vue.component('TitlesComponent', Titles)
Vue.component('TitlePage', TitlePage)
Vue.component('SimpleSlider', SimpleSlider)

Vue.directive('mask', VueMaskDirective)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  data: {
    myserver: '//gpstuuxan.com:',
    myport: '9999', // port activo -> se auto configura
    myport1: '9999', // http
    myport2: '9898', // https,
    wow: null
  },
  created () {
    if (window.location.protocol === 'https:') {
      this.myserver = 'https:' + this.myserver
      this.myport = this.myport2
    } else {
      this.myport = this.myport1
    }
  },
  beforeMount () {
    this.wow = new WOW.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    })
    this.wow.init()
  },
  methods: {
    toTop: function () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    toTopForce: function () {
      window.scrollTo({
        top: 0,
        left: 0
      })
      this.wow.sync()
    }
  },
  render: (h) => h(App)
}).$mount('#app')
