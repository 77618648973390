import { render, staticRenderFns } from "./CardsGaleryData.vue?vue&type=template&id=153cd763&scoped=true&"
import script from "./CardsGaleryData.vue?vue&type=script&lang=js&"
export * from "./CardsGaleryData.vue?vue&type=script&lang=js&"
import style0 from "./CardsGaleryData.vue?vue&type=style&index=0&id=153cd763&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "153cd763",
  null
  
)

export default component.exports