<template>
  <div
    id="AccessManagement"
    class="max-body-container margin"
  >
    <v-row class="container-accessManagement">
      <v-col
        cols="12"
        class="pb-0"
      >
        <TitlesComponent title="Gestión de acceso" />
        <p class="text-content text-center mb-0 mx-auto">
          Controles eficientes de los accesos, disminuyendo las algomeraciones,
          mediante puntos de acceso móviles o fijos o a través de empleados que
          validan los boletos con Handhelds.
        </p>
      </v-col>
      <v-col
        cols="12"
        class="desktop"
      >
        <v-img
          class="desktop-img"
          src="/images/fairs/axess_access_management_collage.webp"
          position="50% 100%"
          height="315px"
        />
      </v-col>
      <v-col
        md="6"
        cols="12"
        align-self="center"
        class="phone"
      >
        <v-img
          class="desktop-img"
          src="/images/fairs/axess_access_management.webp"
          position="50% 100%"
          height="315px"
        />
      </v-col>
      <v-col
        md="6"
        cols="12"
        align-self="center"
        class="phone"
      >
        <v-img
          class="desktop-img"
          src="/images/fairs/Axess_handheld_access_management.webp"
          position="center center"
          height="315px"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'AccessManagement'
}
</script>

<style scoped>
.text-content {
  max-width: 660px;
}

.phone {
  display: none;
}

.desktop {
  display: block;
}

.desktop-img {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
    0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
}

@media (max-width: 959px) {
  .container-accessManagement {
    max-width: 500px;
    margin: 0px auto;
  }

  .phone {
    display: block;
  }

  .desktop {
    display: none;
  }

  .text-content {
    text-align: justify !important;
  }
}
</style>
