<template>
  <div
    id="TicketMaster"
    class="max-body-container margin"
  >
    <v-row class="container-ticketMaster">
      <v-col
        md="7"
        cols="12"
        align-self="center"
      >
        <TitlesComponent
          :title="null"
          class="mb-3"
        >
          <h2 class="titleGeneral">
            Integración directa con <br>
            <img src="/images/icons/tickemaster.webp">
          </h2>
        </TitlesComponent>
        <p class="text-content mx-auto text-center mb-0">
          Tenemos los protocolos de acceso más estrictos utilizados en eventos
          de Formula 1, NFL y Eurocopa.
        </p>
        <p class="text-content mx-auto text-center">
          Velocidad, seguridad y confiabilidad de acceso.
        </p>
        <v-img
          class="mt-3 img-ticketMaster"
          :src="'/images/stadiums/' + img"
          contain
        />
      </v-col>
      <v-col
        md="5"
        cols="12"
        align-self="center"
      >
        <v-img
          src="/images/stadiums/gates-ticketmaster.webp"
          contain
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'TicketMaster',
  data () {
    return {
      img: 'ticketMaster-diagram.webp'
    }
  },
  mounted () {
    this.widthSize()
    window.onresize = this.widthSize
  },
  methods: {
    widthSize: function () {
      const width = document.documentElement.clientWidth
      this.img =
        width > 959
          ? 'ticketMaster-diagram.webp'
          : 'ticketMaster-diagram-phone.webp'
    }
  }
}
</script>

<style scoped>
.title-ticketMaster {
  color: rgb(75, 75, 75);
  font-family: 'Panton';
  font-size: 19px;
  text-align: center;
  max-width: 400px;
}

.text-content {
  max-width: 500px;
}

@media (max-width: 959px) {
  .container-ticketMaster {
    max-width: 500px;
    margin: 0px auto;
  }
}

@media (max-width: 959px) {
  .img-ticketMaster {
    max-width: auto;
    max-height: 85vh !important;
  }
}
</style>
