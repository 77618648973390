<template>
  <div id="Fairs">
    <SimpleSlider img="/images/fairs/slide.webp" />
    <InnovativeTechnology />
    <SystemDesign />
    <RegistrationFlexibility />
    <AccessManagement />
    <LeadTrackingSystem />
  </div>
</template>

<script>
import InnovativeTechnology from '@/components/fairs/InnovativeTechnology.vue'
import SystemDesign from '@/components/fairs/SystemDesign.vue'
import RegistrationFlexibility from '@/components/fairs/RegistrationFlexibility.vue'
import AccessManagement from '@/components/fairs/AccessManagement.vue'
import LeadTrackingSystem from '@/components/fairs/LeadTrackingSystem.vue'

export default {
  name: 'FairsView',
  components: {
    InnovativeTechnology,
    SystemDesign,
    RegistrationFlexibility,
    AccessManagement,
    LeadTrackingSystem
  },
  mounted () {
    this.$root.toTopForce()
  }
}
</script>

<style></style>
