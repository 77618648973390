<template>
  <div id="Home">
    <SliderFlux
      :images="images"
      :captions="captions"
    />
    <AccessVersatility />
    <TicketMaster />
    <SmartGateTurnstiles />
    <!-- <SuccessCases /> -->
    <WhereDoWeMeet />
  </div>
</template>

<script>
import SliderFlux from '@/components/Tools/SliderFlux.vue'
import AccessVersatility from '@/components/Stadiums/AccessVersatility.vue'
import TicketMaster from '@/components/Stadiums/TicketMaster.vue'
import SmartGateTurnstiles from '@/components/Stadiums/SmartGateTurnstiles.vue'
// import SuccessCases from "@/components/Stadiums/SuccessCases.vue";
import WhereDoWeMeet from '@/components/Stadiums/WhereDoWeMeet.vue'
export default {
  name: 'StadiumsView',
  components: {
    SliderFlux,
    AccessVersatility,
    TicketMaster,
    SmartGateTurnstiles,
    // SuccessCases,
    WhereDoWeMeet
  },
  data () {
    return {
      images: [
        '/images/stadiums/nokia_stadium.webp',
        '/images/stadiums/seattle_stadium.webp',
        '/images/stadiums/yankee_stadium.webp'
      ],
      captions: [
        {
          text: 'Sistemas de acceso utilizados en los mejores estadios del mundo'
        },
        {
          text: 'Nuestros equipos verifican la validez de un boleto en solo 0.1 segundo'
        },
        {
          text: 'Somos una empresa mundial que brinda atención local y personalizada al tener oficinas en méxico'
        }
      ]
    }
  },
  mounted () {
    this.$root.toTopForce()
  }
}
</script>
