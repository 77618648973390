<template>
  <div
    id="AboutUs"
    class="max-body-container margin"
  >
    <v-row>
      <v-col cols="12">
        <TitlesComponent title="Nosotros" />
      </v-col>
      <v-col
        md="6"
        cols="12"
        data-wow-duration=".5s"
        data-wow-delay=".5s"
        class="wow fadeInLeft"
        align-self="center"
      >
        <p class="text-content text-justify">
          Somos los representantes de AXESS en Mexico y Centroamérica, uno de
          los principales fabricantes de sistemas de control de acceso y emisión
          de boletos a nivel mundial.
        </p>
        <p class="text-content text-justify">
          Desarrollamos soluciones de acceso, administración y boletaje para su
          negocio, ya sea grande o pequeño, nuestras soluciones abarcan la
          integración de todo el sistema (emisión, venta y control de boletos)
          así como todo el hardware necesario para su implementación (cajeros,
          puntos de ventas, computadoras de mano, impresoras, pórticos
          inteligentes diversos y mas.
        </p>
        <p class="text-content text-justify mb-0 pb-0">
          Contactanos, tenemos la solución a tu medida.
        </p>
      </v-col>
      <v-col
        md="6"
        cols="12"
        data-wow-duration=".5s"
        data-wow-delay=".5s"
        class="wow fadeInRight"
        align-self="center"
      >
        <div class="container-img-us">
          <img
            src="/images/home/map_axxess.webp"
            class="imgCol"
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'AboutUs'
}
</script>

<style scoped>
.container-img-us {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgCol {
  width: 100%;
  display: block;
}

@media (max-width: 1200px) {
  .AboutUs-description {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
}
</style>
