<template>
  <v-app>
    <v-main>
      <NavBar />
      <router-view />
      <FooterMain />
      <ArrowToTop />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import FooterMain from '@/components/FooterMain.vue'
import ArrowToTop from '@/components/Tools/ArrowToTop.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    FooterMain,
    ArrowToTop
  },
  data () {
    return {}
  }
}
</script>

<style>
/* Imports fonts */
@import url('https://fonts.googleapis.com/css2?family=Barlow&family=Roboto&family=Poppins&family=Montserrat&display=swap');

@font-face {
  font-family: Panton;
  src: url('~@/assets/fonts/Panton-Regular.woff');
}

@font-face {
  font-family: RockFordSans;
  src: url('~@/assets/fonts/RockfordSans-Light.otf');
}

/* Global css */

:root {
  --ScrollColor: #ff8136;
  --ScrollColorHover: rgba(0, 0, 0, 0.5);
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--ScrollColor) transparent;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--ScrollColor) !important; /*  #002a9a99; */
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: rgba(0, 42, 154, 0.3); */
  background: var(--ScrollColorHover) !important;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.bordered {
  border: 1px solid red;
}

.text-content {
  color: #444;
  font-family: 'Panton';
  font-size: 17px;
}
.max-app-container {
  max-width: 1800px;
  margin: 0px auto;
  width: 100%;
}

.max-body-container {
  max-width: 1200px;
  margin: 0px auto;
}

.max-body-container.margin {
  margin-top: 50px !important;
}

@media (max-width: 1400px) {
  .max-body-container {
    max-width: 1000px;
  }
}

@media (max-width: 1200px) {
  .max-body-container {
    max-width: 900px;
  }
}

@media (max-width: 959px) {
  .max-app-container {
    max-width: auto;
    margin: 0px 15px;
  }

  .max-body-container {
    max-width: auto;
    margin: 0px 15px;
  }
}
</style>
